import { Component, Vue } from 'vue-property-decorator';
import { RatingsTabs, OppositionType, ProcessType, InscriptionTurn, RankedInscrptionStatus, ScoreMethods, MeritGroupType, ReasonChange, TieTypes, ProcessPhases, GradeType, Gender, ReplacementListTabs, BaseMeritsType, RuleCriteriaType } from '@/Domain/enum';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import * as sortable from 'saviafront/lib/js/compiled/sortable';
import { InscriptionRanked, TieBreak, Exam, TabCalification, TabsList, TabsHash, ProcessMeritGroup, RuleTieBreak, MeritGroup } from '@/Domain/Entities';
import { MdInput, MdRadio, MdSelect, MdTextarea, Modal, SdRule, TurnBox, SdButton, SdRadio, SdTextarea, Tooltip, SdSearcher } from '@/Components';
import ProcessesService from '@/Services/ProcessesService';
import InscriptionService from '@/Services/InscriptionService';
import { ToasterService } from '@/Services/ToasterService';
import ValidationService from '@/Application/Services/ValidationService';
import i18n from '../../lang';
import ExamService from '@/Services/ExamService';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import MeritGroupsService from '@/Services/MeritGroupsService';
import { CryptoService } from '@/Application/Services/CryptoService';

@Component({
    components: { MdInput, MdRadio, MdSelect, MdTextarea, Modal, SdRule, TurnBox, SdButton, SdRadio, SdTextarea, Tooltip, SdSearcher },
    computed: {
        completeVacancies() {
            if (this.$data.turnList.list.length && this.$data.rankedList) {
                return this.$data.turnList.activeTab.totalQualifiedInscriptions === this.$data.turnList.activeTab.vacancies;
            }
        },
        vacanciesTurn() {
            if (this.$data.turnList.list.length) {
                return this.$data.turnList.activeTab.vacancies;
            }
        },
        isSomeTurnWithTiesPending() {
            return this.$data.turnList.list.length ? this.$data.turnList.list.some(element => element.pendingTiebreakers > 0) : this.$data.rankedList.some(element => element.tieType === TieTypes.enum.TIEPENDDING);
        },
        isSomeTieBlockEditing() {
            return this.$data.tiesByTurn.some(tieBlock => tieBlock.isEditing);
        },
        isDisabledValidate() {
            return this.$data.isSavingTieBreak || this.$data.isSomeTieBlockEditing || this.$data.disabledValidate;
        },
        allCandidateEvaluate() {
            if (this.$data.rankedList.length) {
                return this.$data.rankedList.every(element => element.status !== RankedInscrptionStatus.enum.UNQUALIFIED);
            }
        },
        vacanciesDeserted() {
            if (this.$data.turnList.list.length) {
                return this.$data.turnList.activeTab.vacancies - this.$data.turnList.activeTab.totalQualifiedInscriptions;
            }
        },
        factorScaling() {
            if (this.$data.processData.factor.grade !== null) {
                const grade = Number((this.$data.processData.factor.grade).toString().match(/^-?\d+(?:\.\d{0,3})?/));
                this.$data.processData.factor.scale = grade >= 0 && grade < 100 ? 100 - grade : 0;
                return Number((this.$data.processData.factor.scale).toString().match(/^-?\d+(?:\.\d{0,3})?/));
            }
        },
        buttonFactorDisabled() {
            return this.$data.scoreMethodSelected === null && this.$data.someExamListIsScore && this.$data.processData.oppositionType !== OppositionType.enum.TENDER ||
                (this.$data.scoreMethodSelected === ScoreMethods.enum.WITHFACTOR && (this.$data.processData.factor.grade === '' ||
                    (Number(this.$data.processData.factor.grade) + Number(this.$data.processData.factor.scale) !== 100) || Number(this.$data.processData.factor.grade) > 100));
        },
        buttonPanelFactorDisabled() {
            return (this.$data.scoreMethodType === null ||
                (this.$data.scoreMethodType === ScoreMethods.enum.WITHFACTOR && (this.$data.processData.factor.grade === '' ||
                    (Number(this.$data.processData.factor.grade) + Number(this.$data.processData.factor.scale) !== 100) || Number(this.$data.processData.factor.grade) > 100)));
        },
        isVisibleCalculator() {
            return !this.$data.processData.needTieBreak &&
                (this.$data.processData.oppositionType !== OppositionType.enum.TENDER) && this.$data.processData.phase === ProcessPhases.enum.RANKING && (this.$data.someExamListIsScore || this.$data.processData.oppositionType === OppositionType.enum.FREE_DESIGNATION);
        },
        isVisibleDownload() {
            return (!this.$data.processData.needTieBreak && this.$data.processData.phase === ProcessPhases.enum.RANKING) || this.$data.processData.phase === ProcessPhases.enum.FINISH;
        },
        showManualTieBreakButton() {
            return !(this as RankingEdit).ruleTieBreakToManage || !(this as RankingEdit).ruleTieBreakToManage.ruleId;
        },
        isFreeDesignationOppositionType() {
            return this.$data.processData.oppositionType === OppositionType.enum.FREE_DESIGNATION;
        },
        showGenerateListOptions() {
            return (this.$data.someExamListIsScore && (this.$data.processData.oppositionType === OppositionType.enum.OPPOSITION
                || this.$data.processData.oppositionType === OppositionType.enum.NOMINATION))
                || this.$data.processData.oppositionType === OppositionType.enum.FREE_DESIGNATION;
        },
        scoreMethodsOptions() {
            const optionsWhenNotFreeDesignation = this.$data.processData.oppositionType === OppositionType.enum.OPPOSITION ? ScoreMethods.translationsOpposition : ScoreMethods.translationsNomination;
            return this.$data.processData.oppositionType === OppositionType.enum.FREE_DESIGNATION ? ScoreMethods.translationsFreeDesignation : optionsWhenNotFreeDesignation;
        },
        isAnyVacancieOnTurns() {
            if (this.$data.turnList.list.length && this.$data.rankedList) {
                const areTurnsWithVacancies = this.$data.turnList.list.filter(element => element.vacancies > element.totalQualifiedInscriptions).map(element => element.type);
                const turnsWithInscriptions = this.$data.rankedList.filter(element => areTurnsWithVacancies.includes(element.turn));
                return turnsWithInscriptions.length > 0;
            }
        },
        rankedInscriptionsFiltered() {
            if (this.$data.processData.oppositionType !== OppositionType.enum.FREE_DESIGNATION && this.$data.processData.needTieBreak) {
                return this.$data.isShowingOnlyTies 
                ? this.$data.rankedList.filter(element => element.tieType === TieTypes.enum.TIEPENDDING) 
                :  this.$data.rankedList.filter(element => element.totalScore > 0);
            }

            return this.$data.rankedList;
        },
        rankedInscriptionsWithZeroScore() {
            return this.$data.processData.oppositionType !== OppositionType.enum.FREE_DESIGNATION && this.$data.processData.needTieBreak && !this.$data.isShowingOnlyTies && !this.$data.isLoading
                ? this.$data.rankedList.filter(element => element.totalScore === 0) 
                : [];
        },
       
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('languagesStore', { availableLanguages: 'getTranslatableLanguages' })
    },
    watch: {
        '$route.params.id': {
            handler(oldId, newId) {
                if (newId !== oldId) {
                    (this as RankingEdit).initProcess();
                }
            },
            deep: true
        }
    }
})
export default class RankingEdit extends Vue {
    availableLanguages!: string[];
    isSomeTurnWithTiesPending!: boolean;
    isSomeTieBlockEditing!: boolean;
    isDisabledValidate!: boolean;
    showManualTieBreakButton!: boolean;
    isAnyVacancieOnTurns!: boolean;

    processData: any = null;
    RatingsTabs = RatingsTabs;
    ProcessType = ProcessType;
    GradeType = GradeType;
    OppositionType = OppositionType;
    InscriptionTurn = InscriptionTurn;
    RankedInscrptionStatus = RankedInscrptionStatus;
    ScoreMethods = ScoreMethods;
    MeritGroupType = MeritGroupType;
    ReasonChange = ReasonChange;
    TieTypes = TieTypes;
    ProcessPhases = ProcessPhases;
    ReplacementListTabs = ReplacementListTabs;
    RuleCriteriaType = RuleCriteriaType;
    Gender = Gender;
    BaseMeritsType = BaseMeritsType;
    turnList: TabsList<TabCalification> = new TabsList(TabCalification, []);
    rankedList: InscriptionRanked[] = [];
    loading = true;
    tiesByTurn: any[] = [];
    inscriptionEdit: any = null;
    waitingResponse: boolean = false;
    showModalFinish: boolean = false;
    showModalUndoFinish: boolean = false;
    showModalUndo: boolean = false;
    errorTurnFinish: any[] = [];
    errorTurnValidate: any[] = [];
    inscriptionDataExams: any = null;
    hasErrorsTieBreak: boolean = false;
    hasErrorsFinish: boolean = false;
    someExamListIsScore: boolean = false;
    showModalAcceptPercentage: boolean = false;
    hasAcceptPercentage: boolean = false;
    disabledValidate: boolean = false;
    examList: Exam[] = [];
    currentTabType: any = null;
    scoreMethodSelected: any = null;
    showModalApplyRule: boolean = false;
    showModalRemoveRule: boolean = false;
    isSaving: boolean = false;
    ruleTieBreakToManage: any = null;
    ruleTieBreakToManageCopy: any = null;
    isSlideOverVissible: boolean = false;
    submitted: boolean = false;
    meritsGroupsList: MeritGroup[] = [];
    routeNameRanking: string = '';
    menPercentage: number | null = null;
    womenPercentage: number | null = null;
    scoreMethodType: number | null = null;
    isManualTieBreak: boolean = false;
    isSavingTieBreak: boolean = false;
    isShowingOnlyTies: boolean = false;

    $refs!: {
        generationListForm: HTMLFormElement,
        asideForm: HTMLFormElement,
        contentScrollable: HTMLElement,
        sdSlideOver: any,
        refSdSearcher: any
    };

    saveOrderTieBlock(tieBreak) {
        this.isSavingTieBreak = true;
        tieBreak.isEditing = false;
        const objectTosend = {
            orderedRankedInscriptions: tieBreak.ties.map((tie, index) => ({ inscriptionId: tie.id, order: index }))
        };
        ProcessesService.solveTieBlock(this.processData.id, objectTosend)
            .then(() => {
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any);
                this.initProcess();
                this.isSavingTieBreak = false;
                this.isShowingOnlyTies = this.turnList.activeTab && this.turnList.activeTab.pendingTiebreakers === 0 ? this.isShowingOnlyTies : false;
            }).catch(error => console.log(error));
    }

    getIsDisabledButtonValidate() {
        return this.isDisabledValidate;
    }

    getIsSomeTurnWithTiesPending() {
        return this.isSomeTurnWithTiesPending;
    }

    getGenderPercentage() {
        if (!this.rankedList.length) {
            return;
        }

        let menCount = 0;
        let womenCount = 0;

        this.rankedList.forEach((inscription: InscriptionRanked) => {
            if (inscription.gender === Gender.enum.MAN) {
                menCount++;
            } else if (inscription.gender === Gender.enum.WOMAN) {
                womenCount++;
            }
        });

        this.menPercentage = Math.round((menCount / this.rankedList.length) * 100);
        this.womenPercentage = Math.round((womenCount / this.rankedList.length) * 100);
    }

    closeSlideOver() {
        this.$refs.sdSlideOver.setOverflowAutoToBody();
        this.isSlideOverVissible = false;
        if (!this.ruleTieBreakToManage) {
            this.ruleTieBreakToManage = null;
            this.$emit('updateRuleTieBreakToManage', this.ruleTieBreakToManage);
        } else {
            this.ruleTieBreakToManageCopy = _.cloneDeep(this.ruleTieBreakToManage);
        }
    }

    setCriteriaManual() {
        this.ruleTieBreakToManage = new RuleTieBreak({
            name: 'Manual Criteria',
            ruleTieBreakCriteriaGroups: [{
                position: 0,
                ruleTieBreakCriterias: [{
                    ruleType: RuleCriteriaType.MANUAL,
                    ruleDescription: ''
                }]
            }]
        });
        ProcessesService.createRuleTieBreak(this.processData.id, this.ruleTieBreakToManage.toServer()).catch(error => console.log(error));
    }

    manageManualTieBreakResolution() {
        this.isManualTieBreak = true;
        this.calcTies();
        this.setCriteriaManual();
    }

    cancelManageManualTieBreakResolution() {
        this.isManualTieBreak = false;
        ProcessesService.removeRuleTieBreak(this.processData.id).then(() => {
            this.ruleTieBreakToManage = null;
            this.ruleTieBreakToManage = null;
            this.ruleTieBreakToManageCopy = null;
            this.initList();
        }).catch(error => console.log(error));
    }

    showSlideOver() {
        this.isSlideOverVissible = true;
        if (this.ruleTieBreakToManage) {
            return;
        }
        this.ruleTieBreakToManage = new RuleTieBreak({});
        this.$emit('updateRuleTieBreakToManage', this.ruleTieBreakToManage);
        this.ruleTieBreakToManageCopy = _.cloneDeep(this.ruleTieBreakToManage);
    }

    showModalDeleteRule() {
        this.showModalRemoveRule = true;
    }

    getRuleTieBreak() {
        ProcessesService.getRuleTieBreak(this.processData.id).then(
            response => {
                if (response) {
                    this.ruleTieBreakToManage = new RuleTieBreak(response);
                    this.isManualTieBreak = (this.ruleTieBreakToManage && this.ruleTieBreakToManage.name === 'Manual Criteria');
                    this.$emit('updateRuleTieBreakToManage', this.ruleTieBreakToManage);
                    this.ruleTieBreakToManageCopy = _.cloneDeep(this.ruleTieBreakToManage);
                }
            }
        ).catch(error => console.log(error));
    }

    actionTieBreakRule() {
        this.isSaving = true;
        this.ruleTieBreakToManage = _.cloneDeep(this.ruleTieBreakToManageCopy);
        this.$emit('updateRuleTieBreakToManage', this.ruleTieBreakToManage);
        const action = this.ruleTieBreakToManage.ruleId ? ProcessesService.updateRuleTieBreak : ProcessesService.createRuleTieBreak;
        action(this.processData.id, this.ruleTieBreakToManage.toServer()).then(
            () => {
                this.initList();
                this.showModalApplyRule = false;
                this.isSaving = false;
                this.ruleTieBreakToManage.ruleId ? ToasterService.showSuccess(i18n.t('lang.tiebreak.successUpdatedRuleTieBreak') as string)
                    : ToasterService.showSuccess(i18n.t('lang.tiebreak.successCreatedRuleTieBreak') as string);
                this.closeSlideOver();
                this.isShowingOnlyTies = this.turnList.activeTab && this.turnList.activeTab.pendingTiebreakers === 0 ? this.isShowingOnlyTies : false;
            }
        ).catch(() => this.isSaving = false);
    }

    removeTieBreakRule() {
        ProcessesService.removeRuleTieBreak(this.processData.id).then(
            () => {
                this.showModalRemoveRule = false;
                this.ruleTieBreakToManage = null;
                this.$emit('updateRuleTieBreakToManage', this.ruleTieBreakToManage);
                this.ruleTieBreakToManageCopy = null;
                ToasterService.showSuccess(i18n.t('lang.tiebreak.successRemovedRuleTieBreak') as string);
                this.initList();
            }
        ).catch(error => console.log(error));
    }

    getDataExams(inscriptionRanked) {
        InscriptionService.getHistoryProcessExamsGrades(inscriptionRanked.id).then(response => {
            this.inscriptionDataExams = inscriptionRanked;
            this.inscriptionDataExams.infoGrades = response.gradeSummary;
            this.inscriptionDataExams.infoMertis = response.meritSummary.map(meritGroup => new ProcessMeritGroup(meritGroup, this.availableLanguages));
        }).catch(error => this.inscriptionDataExams = null);
    }

    setTurnActive(turn) {
        this.currentTabType = turn;
        this.inscriptionDataExams = null;
        this.inscriptionEdit = null;
        this.$refs.refSdSearcher.removeSearchString();
        this.isShowingOnlyTies = false;
        this.turnList.selectTab(turn);
        this.getRankedListByTurn(this.turnList.activeTab);
        this.$router.push({ name: this.routeNameRanking, hash: this.ReplacementListTabs.enum[this.currentTabType.type] });
    }

    getTieBlock(id: string) {
        return this.tiesByTurn.filter(element => element.firtsTieId === id)[0];
    }

    getTieBlockIndex(id: string) {
        return this.tiesByTurn.findIndex(element => element.firtsTieId === id);
    }

    getRankedListByTurn(turn?: any) {
        this.loading = true;
        const turnType = turn ? turn.type : turn;
        ProcessesService.getDataProcessRankedByTurn(this.$route.params.id, turnType).then(
            response => {
                this.rankedList = response.map(inscriptionRanked => new InscriptionRanked(inscriptionRanked));
                this.$emit('setInscriptionProperties', this.rankedList.length, this.rankedList.length);
                this.getGenderPercentage();
                this.loading = false;
                if ((turn && turn.totalTiebreakers > 0 && this.processData.needTieBreak)) {
                    this.calcTies();
                }

                this.$nextTick(() => {
                    sdPanel.initialize();
                    this.$emit('loadingData');
                });
            }).catch(error => console.log(error));
    }

    calcTies() {
        this.tiesByTurn = [];
        const listElemntsTies = this.rankedList.filter(element => element.tieType !== TieTypes.enum.NOTIE);
        listElemntsTies.forEach(element => {
            const ties = listElemntsTies.filter(result => result.totalScore === element.totalScore);
            if (!this.tiesByTurn.some(tieGroup => tieGroup.ties[0].totalScore === ties[0].totalScore)) {
                this.tiesByTurn.push(new TieBreak(ties));
            }
        });
        this.$nextTick(() => {
            this.tiesByTurn.forEach((element, index) => {
                const refString = 'rankedList' + index;
                this.initializeDragAndDrop(this.$refs[refString][0], element.ties);
            });
            if (this.tiesByTurn.some(tieGroup => tieGroup.ties.some(tie => tie.tieType === TieTypes.enum.TIEPENDDING))) {
                this.scrollToFirstTieBlock();
            }
        });

    }

    initializeDragAndDrop(elementToInitialize, arrayToManage) {
        sortable.initialize(elementToInitialize, (old_index: number, new_index: number) => {
            arrayToManage.splice(new_index, 0, arrayToManage.splice(old_index, 1)[0]);
            arrayToManage.forEach((element, index) => element.order = index);
        }, true, false);
    }

    scrollToFirstTieBlock() {
        const firstTieBoxRef = 'rankedList' + 0;
        const firstTieBox = this.$refs[firstTieBoxRef][0];
        window.scrollTo({ top: firstTieBox.offsetTop, behavior: 'smooth' });
    }

    enterEditMode(tieBreak) {
        tieBreak.isEditing = true;
    }

    getContact(inscription) {
        if (!this.processData.needTieBreak) {
            this.inscriptionEdit = null;
            this.$nextTick(() => {
                this.inscriptionEdit = inscription;
            });
        }

    }

    processToFinish() {
        this.showModalFinish = false;
        ProcessesService.finishProcesses(this.processData.id).then(response => {
            ToasterService.showSuccess(i18n.t('lang.shared.finished') as any);
            this.errorTurnFinish = [];
            this.hasErrorsFinish = false;
            this.initProcess();
        })
            .catch(error => {
                error = error.split('-- ').pop();
                if (error !== '') {
                    this.errorTurnFinish = error.split('&').map(e => Number(e));
                }
                this.hasErrorsFinish = true;
            });
    }

    processToUndo() {
        this.showModalUndo = false;
        ProcessesService.undoRankingProcess(this.processData.id)
            .then(() => {
                this.initProcess();
            })
            .catch(error => console.log(error));
    }

    processToUndoFinish() {
        this.showModalUndoFinish = false;
        ProcessesService.undoFinishPhase(this.processData.id)
            .then(() => {
                this.initProcess();
                ToasterService.showSuccess(i18n.t('lang.toaster.finishedProcessSuccess') as any);
            })
            .catch(error => console.log(error));
    }

    validateTieBreaks() {
        this.disabledValidate = true;
        ProcessesService.validateTieBreaks(this.processData.id).then(response => {
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any);
            this.hasErrorsTieBreak = false;
            this.disabledValidate = false;
            this.isShowingOnlyTies = false;
            this.initProcess();
        }).catch(error => {
            error = error.split('-- ').pop();
            if (error !== '') {
                this.errorTurnValidate = error.split('&').map(e => Number(e));
            }
            this.hasErrorsTieBreak = true;
            this.disabledValidate = false;
        });
    }

    onSubmit() {
        this.submitted = true;
        const isInvalidForm = this.$refs.asideForm.querySelectorAll(':invalid').length || this.$refs.asideForm.querySelectorAll('.error').length;
        if (this.waitingResponse || !this.inscriptionEdit || isInvalidForm) {
            return;
        }
        this.waitingResponse = true;
        this.inscriptionEdit.statusToBack();
        InscriptionService.putInscriptionsManagementContact(this.inscriptionEdit.id, this.inscriptionEdit.infoToServer()).then(
            () => {
                this.initList();
                ToasterService.showSuccess(i18n.t('lang.toaster.inscriptionSave') as any);
                this.waitingResponse = false;
                this.submitted = false;
            })
            .catch(() => {
                this.waitingResponse = false;
                this.submitted = false;
            });

    }

    getProcessAction() {
        return ProcessesService.getDataProcessRankedTurns(this.$route.params.id, this.$data.processData.type);
    }

    initList() {
        this.hasErrorsTieBreak = false;
        this.hasErrorsFinish = false;
        this.errorTurnFinish = [];
        this.errorTurnValidate = [];
        this.inscriptionDataExams = null;
        this.inscriptionEdit = null;
        this.getRuleTieBreak();
        const action = this.getProcessAction();
        action.then(
            response => {
                const myTab = new TabsHash();
                let responseTab;
                if (this.$route.hash) {
                    responseTab = myTab.selectElementWithRouteHash(this.currentTabType, response, this.turnList, this.$route, this.routeNameRanking);
                }
                if (this.$route.params.hash) {
                    responseTab = myTab.selectElementWithParamsHash(this.currentTabType, response, this.turnList, this.$route, this.$router, this.routeNameRanking);
                }
                if (!this.$route.params.hash && !this.$route.hash) {
                    responseTab = myTab.selectElementWithoutHash(this.currentTabType, response, this.turnList, this.$router, this.routeNameRanking);
                }
                this.currentTabType = responseTab.elementActive;
                this.turnList = responseTab.list;
                this.getRankedListByTurn(this.turnList.activeTab);
            })
            .catch(error => this.loading = false);
    }

    applyFactor(scoreMethodType) {
        this.processData.factor.scoreMethod = scoreMethodType;
        ProcessesService.updateDataProcessFactor(this.$route.params.id, this.processData.factor).then(
            response => {
                if (response) {
                    if (this.processData.scoreMethod !== ScoreMethods.enum.WITHFACTOR) {
                        this.processData.factor.grade = null;
                        this.processData.factor.scale = null;
                    }
                    this.initProcess();
                }
                this.scoreMethodType = null;
            }
        ).catch(error => console.log(error));
    }

    acceptModalGenerateList() {
        this.hasAcceptPercentage = true;
        this.generateList();
    }

    generateList() {
        this.determineScoreMethod();
        if (this.processData.scoreMethod === ScoreMethods.enum.WITHFACTOR && !ValidationService.validateFormRequired(this.$refs.generationListForm)) {
            return;
        }
        if (this.processData.applyExamPercentage && !this.hasAcceptPercentage) {
            this.showModalAcceptPercentage = true;
            return;
        }
        ProcessesService.generateList(this.$route.params.id, this.processData.factor).then(
            response => {
                this.showModalAcceptPercentage = false;
                this.scoreMethodSelected = null;
                if (response) {
                    this.processData.phase = ProcessPhases.enum.RANKING;
                    this.initProcess();
                }
            }
        ).catch(error => console.log(error));
    }

    determineScoreMethod() {
        const { oppositionType } = this.$data.processData;
        const isOppositionTypeFreeDesignation = oppositionType === OppositionType.enum.FREE_DESIGNATION;
        const isNotTenderOppositionTypeWithExamsScored = oppositionType !== OppositionType.enum.TENDER && this.someExamListIsScore;

        if (isOppositionTypeFreeDesignation || isNotTenderOppositionTypeWithExamsScored) {
            this.processData.factor.scoreMethod = this.scoreMethodSelected;
            return;
        }
        this.processData.factor.scoreMethod = ScoreMethods.enum.WITHOUTFACTOR;
    }

    downloadCSVPosition() {
        ProcessesService.getCSVListPosition(this.$route.params.id);
    }

    downloadCSVContactDataList() {
        ProcessesService.getCSVContactDataList(this.$route.params.id);
    }

    downloadCSVVacancies() {
        ProcessesService.getCSVListVacancies(this.$route.params.id);
    }

    initProcess() {
        this.hasAcceptPercentage = false;
        ProcessesService.getDataProcessWithFactor(this.$route.params.id).then((response: any) => {
            this.processData = response;
            this.$emit('setPropertiesProcessData', this.processData);
            if (this.processData.oppositionType !== OppositionType.enum.NOMINATION && this.processData.oppositionType !== OppositionType.enum.FREE_DESIGNATION) {
                this.getMeritsGroups();
            }
            if (this.processData.phase >= ProcessPhases.enum.RANKING) {
                this.initList();
            } else {
                this.loading = false;
                if (this.processData.oppositionType === OppositionType.enum.OPPOSITION) {
                    this.scoreMethodSelected = ScoreMethods.enum.WITHOUTFACTOR;
                }
                this.$nextTick(() => {
                    notification.initialize();
                });
            }
        }).catch(error => console.log(error));

        ExamService.getExam(this.$route.params.id).then(
            response => {
                this.examList = response.exams.map(exam => new Exam(exam));
                this.someExamListIsScore = this.examList.some(exam => exam.gradeType === GradeType.enum.SCORE);
            }).catch(error => console.log(error));
    }

    getMeritsGroups() {
        MeritGroupsService.getMeritGroups(this.$route.params.id)
            .then((responseMeritGroup: MeritGroup[]) => {
                this.meritsGroupsList = responseMeritGroup.map(element => new MeritGroup(element));
            })
            .catch(error => console.log(error));
    }

    toggleValueisShowingOnlyTies() {
        this.isShowingOnlyTies = !this.isShowingOnlyTies;
    }

    async getEncript(payload) {
        return await CryptoService.encrypt(payload);
    }

    async goToInscriptionEdit(nif: string) {
        const encriptedNif = await this.getEncript(nif);
        this.$router.push(
            {
                name: 'InscriptionEdit',
                params:
                {
                    id: this.$route.params.id,
                    dni: encriptedNif,
                    from: this.routeNameRanking, 
                    hash: ReplacementListTabs.enum[this.currentTabType.type],
                    title: 'lang.shared.list'
                }
            });
    }

    mounted() {
        this.routeNameRanking = 'RankingEdit';
        this.initProcess();
    }
}
