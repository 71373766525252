import Enum from '../interfaces/IEnum';

export const Constants: Enum = {
    name: {
        CONVOCA: 'Convoca'
    },
    savia: {
        googleAnalyticsOptInBrowserAddOn: 'https://tools.google.com/dlpage/gaoptout?hl=de%3Cbr/',
        sedeAGPD: 'https://sedeagpd.gob.es/sede-electronica-web/',
        dpoEmail: 'dpo@savia.net',
        urlSavia: 'https://www.savia.net/',
        phone: '913142815',
        apiKey: 'AIzaSyB6KR9J_PxVodaCee6Wl7UVRy-VpY54gg8'
    },
    country: {
        SPAIN_CODE: 724
    },
    fileImporter: {
        VALID_EXTENSIONS: ['.bmp', '.jpg', '.jpeg', '.pdf', '.png'],
        INVALID_FILE_NAME: ['"', '<', '>', '|', ':', '*', '?', '/', '\\'],
        PDF: '.pdf',
        CSV: '.csv',
        MAX_SIZE: 10 * 1024 * 1024 // 10MB on binary sense
    },
    inscription: {
        MAX_YEARS: 99.999,
        MAX_FULL_YEARS: 99
    },
    language: {
        ES_ES: 'es-ES',
        CA_ES: 'ca-ES',        
        EU_ES: 'eu-ES',
        GL_ES: 'gl-ES',
        VL_ES: 'pt-PT',
        primary: 0,
        secondary: 1
    },
    translation: {
        'es-ES': 'es',
        'ca-ES': 'ca',        
        'eu-ES': 'eu',
        'gl-ES': 'gl',
        'pt-PT': 'ca'
    },
    sharedLengths: {
        TWENTY: 20,
        ONE_THOUSAND: 1000,
        THREE_THOUSAND: 3000
    },
    process: {
        ZERO: 0,
        YEAR_INITIAL: 2017,
        YEAR_INITIAL_CONVOCA: 2018,
        PROCESS_TYPES: [0, 1, 2]
    },
    routeActive: {
        DASHBOARD: '/dashboard',
        CALLS: '/calls/',
        BAGS: '/bags/',
        POST_PROVISION: '/postProvisions/',
        BOEP: '/BOEP/',
        LIST_MANAGEMENT: '/listManagement/',
        RATINGS: '/ratings/',
        REPLACEMENT: '/replacement/',
        PROCESS: '/process/',
        CANDIDATES: '/candidates/'
    },
    uint: new Uint8Array([21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32]),
    sign: '82fM$Hrm5bWm'
};
