import { Component, Vue } from 'vue-property-decorator';
import { Process, Filter, SorterNew, FilterElement, ProcessConfigGroup } from '@/Domain/Entities';
import ProcessesService from '@/Services/ProcessesService';
import EntityService from '@/Services/EntityService';
import { ProcessStatus, ProcessType, ProcessPhases, OppositionType, Constants } from '@/Domain/enum';
import { SdFilter, MdRadio } from '@/Components';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

@Component({
    components: {
        SdFilter,
        MdRadio
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('processListStore', { filters: 'getFilters' }),
        ...mapGetters('processConfigStore', { processConfigGroups: 'getProcessConfigGroups' }),
        orderedProcess() {
            return (this as ProcessList).sorter.sortBy((this as ProcessList).filter.filteredData);
        },
        adminBlockedProcessIds() {
            return this.$store.state.userStore.adminBlockedProcessIds || [];
        }
    },
    methods: {
        ...mapMutations('processListStore', ['setFilters']),
        ...mapActions('processListStore', ['setCheckboxGroupsSubgroups']),
        ...mapActions('processListStore', ['setEntities'])
    },
    watch: {
        filter() {
            (this as ProcessList).setFilters((this as ProcessList).filter.filterElements);
        }
    }
})
export default class ProcessList extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    filters!: FilterElement[];
    processConfigGroups!: ProcessConfigGroup[];
    showModal: boolean = false;
    showModalCopy: boolean = false;
    showFilter: boolean = true;
    copyingProcess: boolean = false;
    modalTypeProcess: boolean = false;
    deleteId = '';
    processList: Process[] = [];
    filter = new Filter();
    sorter = new SorterNew(['year', 'title'], ['desc', 'asc']);
    entityList: any[] = [];
    loading: boolean = true;
    ProcessStatus = ProcessStatus;
    ProcessType = ProcessType;
    ProcessPhases = ProcessPhases;
    OppositionType = OppositionType;
    Constants = Constants;
    processIdToCopy: string = '';
    processTypeSelected: string = '';

    setFilters!: (arg1: FilterElement[]) => void;
    setCheckboxGroupsSubgroups!: (arg: { processConfigGroups: ProcessConfigGroup[], currentLanguage: string }) => void;
    setEntities!: (arg1: Array<{id: string, name: string}>) => void;

    sortBy(key: string) {
        this.sorter.updateKeys([key]);
    }

    openModalTypeProcess() {
        this.modalTypeProcess = true;
    }

    createProcess() {
        this.modalTypeProcess = false;
        this.$router.push({name: ProcessType.routeNew[this.processTypeSelected]});
    }

    copyProcess() {
        this.showModalCopy = false;
        this.copyingProcess = true;
        ProcessesService.copyProcess(this.processIdToCopy, Number(this.processTypeSelected))
        .then(response => {
            this.resetPropertiesToCopy();
            ToasterService.showSuccess(i18n.t('lang.toaster.saveCopyProcess') as any);
            this.$router.push({name: ProcessType.routeEdit[this.processTypeSelected],  params: { id: response.id }});
        })
        .catch(() => {
            this.resetPropertiesToCopy();
            this.processTypeSelected = '';
        });
    }

    resetPropertiesToCopy() {
        this.showModalCopy = false;
        this.copyingProcess = false;
        this.processIdToCopy = '';
    }

    deleteProcess() {
        const self = this;
        self.showModal = false;
        ProcessesService.removeProcess(this.deleteId, Number(this.processTypeSelected))
            .then(() => {
                const processIndex = self.processList.indexOf(self.processList.filter(elem => elem.id === self.deleteId)[0]);
                self.processList.splice(processIndex, 1);
                self.showFilter = false;
                self.filter.setData(self.processList);
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                this.$nextTick(() => {
                    self.showFilter = true;
                });
            })
            .catch(() => {
                self.showModal = false;
                this.processTypeSelected = '';
            });
    }

    openModalCopy(processId, processType) {
        this.showModalCopy = true;
        this.processIdToCopy = processId;
        this.processTypeSelected = processType;
    }

    openModalDelete(processId, processType) {
        this.showModal = true;
        this.deleteId = processId;
        this.processTypeSelected = processType;
    }

    closeModal() {
        this.showModal = false; 
        this.modalTypeProcess = false;
    }

    getEntityList() {
        EntityService.getEntityList()
            .then(result => {
                this.entityList = result;
                this.processList.forEach(process => process.setEntityName(this.entityList));
                this.setEntities(this.entityList);
                this.filter.setFilterElements(this.filters);                
                this.filter.setData(this.processList);
                this.loading = false;
            })
            .catch(() => this.loading = false);
    }

    getProcessListByType() {
        Promise.all([ProcessesService.getProcessListByType(ProcessType.enum.CALL), ProcessesService.getProcessListByType(ProcessType.enum.BAG), ProcessesService.getProcessListByType(ProcessType.enum.POSTPROVISION)])
        .then((response: any) => {
            const processListCall = response[ProcessType.enum.CALL].map(process => new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
            const processListBag = response[ProcessType.enum.BAG].map(process => new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
            const processListPostProvision = response[ProcessType.enum.POSTPROVISION].map(process => new Process(process, undefined, this.$store.state.processConfigStore.processConfigGroups));
            this.processList = processListCall.concat(processListBag, processListPostProvision);
            this.getEntityList();
        })
        .catch(error => console.log(error));
    }

    created() {
        this.loading = true;
        this.setCheckboxGroupsSubgroups({processConfigGroups: this.processConfigGroups, currentLanguage: this.currentLanguage});
        this.getProcessListByType();
    }
}
