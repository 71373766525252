import { Component, Vue } from 'vue-property-decorator';
import { Replacement, ClientInfo, Filter, SorterNew, FilterElement, ProcessConfigGroup } from '@/Domain/Entities';
import { ProcessType } from '@/Domain/enum';
import MasterDataService from '@/Services/MasterDataService';
import ReplacementService from '@/Services/ReplacementService';
import { ToasterService } from '@/Services/ToasterService';
import { Toggle, SdFilter, ImportReplacementList } from '@/Components';
import Layout from '../Layout';
import i18n from '../../lang';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ReplacementOriginType } from '@/Domain/enum/ReplacementOriginType';

@Component({
    components: {
        Toggle,
        SdFilter,
        ImportReplacementList
    },
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat'}),
        ...mapGetters('replacementListStore', { filters: 'getFilters' }),
        ...mapGetters('processConfigStore', { processConfigGroups: 'getProcessConfigGroups' }),
        orderedReplacement() {
            return (this as ReplacementList).sorter.sortBy((this as ReplacementList).filter.filteredData);
        }
    },
    methods: {
        ...mapMutations('replacementListStore', ['setFilters']),
        ...mapActions('replacementListStore', ['setCheckboxGroupsSubgroups'])
    },
    watch: {
        filter() {
            (this as ReplacementList).setFilters((this as ReplacementList).filter.filterElements);
        }
    }
})
export default class ReplacementList extends Vue {
    currentLanguage!: string;
    dateFormat!: string;
    filters!: FilterElement[];
    processConfigGroups!: ProcessConfigGroup[];

    allReplacement: any[] = [];
    filter = new Filter();
    sorter = new SorterNew(['name', 'creationDate'], ['asc', 'desc']);
    loading: boolean = false;
    ProcessType = ProcessType;
    isClientInfoReplacementListsVisible: boolean = false;
    clientInfo: ClientInfo = new ClientInfo({});
    showModalDelete: boolean = false;
    replacementIdToDelete: string = '';
    showFilter: boolean = true;
    isImportReplacementListSlideOverVissible: boolean = false;
    ReplacementOriginType = ReplacementOriginType;

    setFilters!: (arg1: FilterElement[]) => void;
    setCheckboxGroupsSubgroups!: (arg: { processConfigGroups: ProcessConfigGroup[], currentLanguage: string }) => void;

    $refs!: {
        replacementListActionsPanel: HTMLElement
    };

    goToCreateReplacementList() {
        this.$router.push({
            name: 'ReplacementNew'
        });
    }

    sortBy(key: string) {
        this.sorter.updateKeys([key]);
    }

    getReplacementList() {
        ReplacementService.getReplacementList().then(
            response => {
                this.allReplacement = response.map(replacement => new Replacement(replacement, this.processConfigGroups));
                this.filter.setData(this.allReplacement);
                this.filter.setFilterElements(this.filters);
                this.loading = false;
            })
            .catch(() => this.loading = false);
    }
    
    changeVisibleReplacementList(replacement) {
        const isVisibleReplacement = {isVisible: replacement.isVisible};
        ReplacementService.updateReplacementIsVisible(replacement.id, isVisibleReplacement)
        .then(() => {
            this.showFilter = false;
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.$nextTick(() => {
                this.showFilter = true;
            });
        })
        .catch(() => {
            ToasterService.showError(i18n.t('lang.toaster.errorGeneralTitle') as string);
            replacement.isVisible = !replacement.isVisible;
        });
    }

    changeVisibleClientInfo() {
        const isVisibleClientInfo = {replacementListsVisible: this.isClientInfoReplacementListsVisible};
        ReplacementService.updateClientInfoReplacementListsVisible(isVisibleClientInfo)
        .then(() => {
            ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            this.clientInfo.setReplacementListsVisible(this.isClientInfoReplacementListsVisible);
        })
        .catch(() => {
            ToasterService.showError(i18n.t('lang.toaster.errorGeneralTitle') as string);
            this.isClientInfoReplacementListsVisible = !this.isClientInfoReplacementListsVisible;
        });
    }

    openModalDelete(replacement: Replacement) {
        if (replacement.isModify) {
            return;
        }
        this.showModalDelete = true;
        this.replacementIdToDelete = replacement.id;
    }

    deleteReplacementList() {
        this.showModalDelete = false;
        ReplacementService.removeReplacement(this.replacementIdToDelete)
            .then(() => {
                const listIndex = this.allReplacement.indexOf(this.allReplacement.filter(elem => elem.id === this.replacementIdToDelete)[0]);
                this.showFilter = false;
                this.allReplacement.splice(listIndex, 1);
                this.filter.setData(this.allReplacement);
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                this.updateDataPlan();
                this.$nextTick(() => {
                    this.showFilter = true;
                });
            })
            .catch(() => {
                this.showModalDelete = false;
                this.replacementIdToDelete = '';
            });
    }

    async updateDataPlan() {
        await (this.$parent.$parent as Layout).getSubscriptionPlan();
    }

    initClientInfo() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => { 
                this.clientInfo = new ClientInfo(responseMasterData);
                this.isClientInfoReplacementListsVisible = this.clientInfo.getReplacementListsVisible();
            })
            .catch(error => console.log(error));
    }

    toggleImportReplacementListSlideOver() {
        this.isImportReplacementListSlideOverVissible = !this.isImportReplacementListSlideOverVissible;
    }

    mounted() {
        this.loading = true;
        this.setCheckboxGroupsSubgroups({processConfigGroups: this.processConfigGroups, currentLanguage: this.currentLanguage});
        this.initClientInfo();
        this.getReplacementList();
        this.$nextTick(() => {
            sdPanel.initialize();
        });
    }
    
}
