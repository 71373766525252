import { Component, Vue } from 'vue-property-decorator';
import { Candidate } from '@/Domain/Entities';
import { ProcessType, ProcessPhases, OppositionType, ManagementTabs, InscriptionStatus, RatingsTabs } from '@/Domain/enum';
import CandidateService from '@/Services/CandidateService';
import { ToasterService } from '@/Services/ToasterService';
import { mapGetters, mapMutations, mapState } from 'vuex';
import i18n from '../../lang';
import { SdSlideOver } from '..';
import { CryptoService } from '@/Application/Services/CryptoService';
import { ReplacementOriginType } from '@/Domain/enum/ReplacementOriginType';

@Component({
    computed: {
        ...mapState('languagesStore', { currentLanguage: 'current' }),
        ...mapGetters('candidateStore', { candidateInfo: 'getCandidateInfo' }),
        isInvalidNIF() {
            return (this as CandidatesSearch).disabledSearchButton;
        },
        adminBlockedProcessIds() {
            return this.$store.state.userStore.adminBlockedProcessIds || [];
        }
    },
    methods: {
        ...mapMutations('candidateStore', ['setCandidateInfo'])
    }
})

export default class CandidatesSearch extends Vue {
    currentLanguage!: string;
    userDNI!: string;
    candidateInfo!: Candidate | null;
    enrolledReplacementIds!: string[];
    adminBlockedProcessIds!: string[];

    nifToSearch: string = '';
    disabledSearchButton: boolean = true;
    candidate: Candidate | null = null;
    loading: boolean = false;

    ProcessType = ProcessType;
    ProcessPhases = ProcessPhases;
    OppositionType = OppositionType;
    ManagementTabs = ManagementTabs;
    InscriptionStatus = InscriptionStatus;
    ReplacementOriginType = ReplacementOriginType;

    setCandidateInfo!: (arg1: Candidate | null) => void;

    $refs!: {
        slideOver: SdSlideOver;
    };

    copyNIF() {
        if (this.candidate) {
            navigator.clipboard.writeText(this.candidate.nif);
            ToasterService.showSuccess(i18n.t('lang.candidates.copyNifClipboard') as any);
        }
    }

    downloadCSVCandidatePassedExams() {
        if (this.candidate) {
            CandidateService.getCSVCandidatePassedExams(this.candidate.nif);
        }
    }

    updateDisabledSearchButton($event: any) {
        this.disabledSearchButton = !$event.includes(true);
    }

    searchCandidate() {
        if (this.userDNI === this.nifToSearch) {
            this.candidate = new Candidate({});
            return;
        }

        this.loading = true;
        CandidateService.getCandidateInfo(this.nifToSearch)
            .then(response => {
                this.candidate = new Candidate(response);
                this.loading = false;
                this.disabledSearchButton = false;
                if (this.candidate.nif) {
                    this.setCandidateInfo(this.candidate);
                }
            })
            .catch(error => console.log(error));
    }

    removeCandidate() {
        this.candidate = null;
        this.disabledSearchButton = true;
        this.nifToSearch = '';
        this.setCandidateInfo(null);
    }

    closeSlideOver() {
        this.$emit('closeSlideOver');
        this.$refs.slideOver.setOverflowAutoToBody();
    }

    navigateTo(routeName: string, params: Record<string, any>) {
        this.$router.push({ name: routeName, params });
        this.closeSlideOver();
    }

    async navigateToInscriptionEdit(processId: string, nif: string) {
        if (this.adminBlockedProcessIds.includes(processId)) { return; }
        const encriptedNif = await CryptoService.encrypt(nif);
        this.navigateTo('InscriptionEdit', {
            id: processId,
            dni: encriptedNif,
            from: this.$route.name,
            title: 'lang.exams.applicants'
        });
    }

    navigateToListManagementEdit(processId: string, inscriptionStatus: string) {
        if (this.adminBlockedProcessIds.includes(processId)) { return; }
        this.navigateTo('ListManagementEdit', {
            id: processId,
            hash: ManagementTabs.enum[inscriptionStatus],
            fromPage: this.$route.name
        });
    }

    navigateToRatingsEdit(processId: string, oppositionType: number) {
        if (this.adminBlockedProcessIds.includes(processId)) { return; }
        this.navigateTo('RatingsEdit', {
            id: processId,
            fromPage: this.$route.name,
            tabActive: this.geRatingsEditActiveTab(oppositionType)
        });
    }

    navigateToReplacementStatusContract(replacement: { replacementListCandidateId: string, replacementListId: string }) {
        this.navigateTo('ReplacementStatusContract', {
            id: replacement.replacementListId,
            replacementListCandidateId: replacement.replacementListCandidateId
        });
    }

    geRatingsEditActiveTab(oppositionType: number) {
        const activeTabWhenOppositionTypeNotFreeDesignation = oppositionType === OppositionType.enum.TENDER ? RatingsTabs.enum.MERTIS : RatingsTabs.enum.EXAMS;

        return oppositionType === OppositionType.enum.FREE_DESIGNATION ? RatingsTabs.enum.LISTS : activeTabWhenOppositionTypeNotFreeDesignation;
    }
    
    mounted() {
        if (this.candidateInfo) {
            this.candidate = this.candidateInfo;
            this.nifToSearch = this.candidateInfo.nif;
            this.disabledSearchButton = false;
        }
    }
}
