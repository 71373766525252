import { Constants } from '@/Domain/enum';

export class CryptoService {
  static async encrypt(payload: string): Promise<string> {
    try {
      const encoder = new TextEncoder();

      const keyMaterial = await window.crypto.subtle.importKey(
        'raw',
        encoder.encode(Constants.sign),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
      );

      const key = await window.crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt: new Uint8Array(16),
          iterations: 100000,
          hash: 'SHA-256'
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt']
      );

      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: 'AES-GCM',
          iv: Constants.uint
        },
        key,
        encoder.encode(payload)
      );

      const base64Encoded = btoa(
        String.fromCharCode(...new Uint8Array(encryptedData))
      );
      return base64Encoded;
    } catch (error) {
      console.error('Encryption error:', error);
      return '';
    }
  }

  static async decrypt(token: string): Promise<string> {
    try {
      const encoder = new TextEncoder();
      const keyMaterial = await window.crypto.subtle.importKey(
        'raw',
        encoder.encode(Constants.sign),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
      );

      const key = await window.crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt: new Uint8Array(16),
          iterations: 100000,
          hash: 'SHA-256'
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        false,
        ['decrypt']
      );

      const binaryData = atob(token);
      const encryptedArray = new Uint8Array(
        binaryData.split('').map(char => char.charCodeAt(0))
      );
      const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: Constants.uint
        },
        key,
        encryptedArray
      );

      const decoder = new TextDecoder();
      return decoder.decode(decryptedData);
    } catch (error) {
      console.error('Decryption error:', error);
      return '';
    }
  }
}
