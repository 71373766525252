import { Component, Vue } from 'vue-property-decorator';
import { Filter, InscriptionReplacement, TabsList, Tab, TabsHash, SorterNew, JobOffer, FilterElement, ReplacementCandidate } from '@/Domain/Entities';
import { StatusReplacement, InscriptionTurn, ReplacementListTabs, OppositionType, ReplacementReasons, MassiveNotificationReceiversType, Gender, YesNo, Constants, CandidateMoveOption, CandidateMoveOptionLabel } from '@/Domain/enum';
import { SdFilter, SdInputMail, SdSlideOver, Toggle } from '@/Components';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import ReplacementService from '@/Services/ReplacementService';
import { mapState, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import MasterDataService from '@/Services/MasterDataService';
import ReplacementCandidatesService from '@/Services/ReplacementCandidatesService';
import MoveReplacementCandidate from '@/Components/MoveReplacementCandidate/MoveReplacementCandidate';

@Component({
    components: {
        SdFilter, Toggle, SdSlideOver, SdInputMail, MoveReplacementCandidate
    },
    computed: {
        filteredData() {
            return this.$data.sorter.sortBy(this.$data.filter.filteredData);
        },
        isReceiverTypeDistinctAll() {
            return this.$data.newJobOffer.receiverType !== MassiveNotificationReceiversType.enum.ALL;
        },
        setCandidatesSendNewJobOffer() {
            let candidatesToSend: string[] = [];
            if (this.$data.newJobOffer.includeCandidatesWithJobProposals) {
                candidatesToSend = this.$data.inscriptionList.filter((inscription: InscriptionReplacement) => inscription.canReceiveJobProposalsWhileIsWorkingOrBusy()).map((inscription: InscriptionReplacement) => inscription.replacementListCandidateId);
            }
            if (this.$data.newJobOffer.receiverType === this.$data.MassiveNotificationReceiversType.enum.FILTERED) {
                this.$data.newJobOffer.candidates = [...candidatesToSend, ...this.$data.sorter.sortBy(this.$data.inscriptionList).filter(inscription => inscription.order + 1 >= Number(this.$data.newJobOffer.sendFrom) && inscription.order + 1 <= Number(this.$data.newJobOffer.sendTo) && inscription.replacementStatus === StatusReplacement.enum.AVAILABLE).map(inscription => inscription.replacementListCandidateId)];
            }
            if (this.$data.newJobOffer.receiverType === this.$data.MassiveNotificationReceiversType.enum.ALL) {
                this.$data.newJobOffer.candidates = [...candidatesToSend, ...this.$data.inscriptionList.filter(inscription => inscription.replacementStatus === StatusReplacement.enum.AVAILABLE).map(inscription => inscription.replacementListCandidateId)];
            }
            if (this.$data.newJobOffer.candidates && !this.$data.newJobOffer.candidates.length) {
                this.$data.submitted = false;
            }
            return this.$data.newJobOffer.candidates;
        },
        isAddressCountrySpain() {
            return this.$data.replacementListCandidateSelected.address.country === Constants.country.SPAIN_CODE;
        },
        isReplacementCandidateFormDisabled() {
            return !this.$data.isReplacementListCandidateEditting;
        },
        turns() {
            return this.$data.turnList.list.sort((a: Tab, b: Tab) => a.type - b.type);
        },
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat' }),
        ...mapGetters('listReplacementListStore', { seeInscriptionsExcludedOrResignation: 'getSeeInscriptionsExcludedOrResignation' }),
        ...mapGetters('listReplacementListStore', { filters: 'getFilters' })
    },
    methods: {
        ...mapMutations('listReplacementListStore', ['setSeeInscriptionsExcludedOrResignation']),
        ...mapMutations('listReplacementListStore', ['setFilters'])
    },
    watch: {
        filter() {
            this.$data.setFilters(this.$data.filter.filterElements);
        },
        inscriptionsExcludedOrResignation() {
            this.$data.setSeeInscriptionsExcludedOrResignation(this.$data.inscriptionsExcludedOrResignation);
        }
    }
})
export default class ListReplacementList extends Vue {
    setCandidatesSendNewJobOffer!: number;
    seeInscriptionsExcludedOrResignation!: boolean;
    filters!: FilterElement[];

    setSeeInscriptionsExcludedOrResignation!: (arg1: boolean) => void;
    setFilters!: (arg1: FilterElement[]) => void;

    StatusReplacement = StatusReplacement;
    InscriptionTurn = InscriptionTurn;
    ReplacementListTabs = ReplacementListTabs;
    OppositionType = OppositionType;
    ReplacementReasons = ReplacementReasons;
    Gender = Gender;
    YesNo = YesNo;
    Constants = Constants;

    filter = new Filter();
    sorter = new SorterNew(['order'], ['asc', 'desc']);
    loading: boolean = false;
    replacementListData: any = {};
    isSavingTieBreak: boolean = false;
    inscriptionList: InscriptionReplacement[] = [];
    inscriptionsExcludedOrResignation: boolean = false;
    replacementListId = '';
    turnList: TabsList<Tab> = new TabsList(Tab, []);
    currentTabType: any = null;
    fromPage: string = '';
    showModalWarning: boolean = false;
    replacementListModified: boolean = false;
    showSlideOver: boolean = false;
    newJobOffer: JobOffer = new JobOffer({});
    moment = moment;
    submitted: boolean = false;
    peopleAvailableOnActiveTurn: boolean = false;
    MassiveNotificationReceiversType = MassiveNotificationReceiversType;
    replacementListCandidateSelected: ReplacementCandidate = new ReplacementCandidate({});
    isSlideOverVissible: boolean = false;
    countries: any;
    autonomousCommunityList: any;
    streetTypes: any;
    provinces: any;
    nationalities: any;
    turnsOnProcess!: { [k: string]: any; };
    municipalities: [{ name: string, id: number, disabled: boolean }] | [] = [];
    showMoveReplacementCandidateModal: boolean = false;
    candidateToMove: string = '';
    candidatePosition: number | null = null;

    $refs!: {
        contentScrollable: HTMLElement,
        sdFilter: SdFilter,
        panelDownload: HTMLElement,
        panelMassiveContact: HTMLElement,
        massiveNotificationForm: HTMLFormElement,
        sdSlideOver: SdSlideOver,
        sdSlideOverReplacementCandidateInfo: SdSlideOver,
        replacementCandidateForm: HTMLFormElement
    };
    isReplacementListCandidateEditting: boolean = false;

    backToReplacementList() {
        this.$router.push({
            name: this.fromPage ? this.fromPage : 'replacement'
        });
    }
    
    goToStatusContractReplacement(inscription: InscriptionReplacement) {
        this.$router.push({ name: 'ReplacementStatusContract', params: { id: this.replacementListData.id, replacementListCandidateId: inscription.replacementListCandidateId, hash: this.ReplacementListTabs.enum[this.currentTabType] } });
    }

    goToShippingHistory() {
        this.$router.push({ name: 'ShippingHistoryList', params: { id: this.replacementListData.id, name: this.replacementListData.name, hash: this.ReplacementListTabs.enum[this.currentTabType] } });
    }

    setTurnActive(turn) {
        this.currentTabType = turn.type;
        this.$router.push({ name: 'ListReplacementList', hash: this.ReplacementListTabs.enum[this.currentTabType] });
        this.turnList.selectTab(turn);
        this.getInscriptionByTurn(turn);
    }

    getInscriptionByTurn(turn: Tab | null) {
        this.inscriptionList = [];
        const turnType = turn ? turn.type : turn;
        ReplacementService.getDataReplacementRankedByTurn(this.replacementListId, turnType).then(
            response => {
                this.inscriptionList = response.map(inscription => new InscriptionReplacement(inscription));
                this.peopleAvailableOnActiveTurn = this.inscriptionList.filter(inscription => inscription.replacementStatus === StatusReplacement.enum.AVAILABLE || inscription.canReceiveJobProposalsWhileIsWorkingOrBusy()).length > 0;
                this.filter.setData(this.inscriptionList);
                this.filter.setFilterElements(this.filters);
                this.loading = false;
                this.inscriptionList = this.sorter.sortBy(this.filter.filteredData);
                this.$nextTick(() => {
                    this.$refs.sdFilter.createCopyValue();
                });
            }).catch(() => this.loading = false);
    }

    downloadCSVList() {
        ReplacementService.getCSVToDownload(this.replacementListId);
    }

    downloadCSVListContactData() {
        ReplacementService.getCSVContactDataToDownload(this.replacementListId);
    }

    downloadCSVListContactHistory() {
        ReplacementService.getCSVContactHistoryToDownload(this.replacementListId);
    }

    downloadCSVListContractHistory() {
        ReplacementService.getCSVContractHistoryToDownload(this.replacementListId);
    }

    downloadCSVListStatesHistory() {
        ReplacementService.getCSVStatesHistoryToDownload(this.replacementListId);
    }

    acceptModalWarning(inscription) {
        this.showModalWarning = false;
        inscription.moveInscription = true;
    }

    getReplacementById() {
        ReplacementService.getReplacementById(this.replacementListId).then(list => {
            this.replacementListData = list;
            const myTab = new TabsHash();
            let responseTab;
            if (this.$route.hash) {
                responseTab = myTab.selectElementWithRouteHash(this.currentTabType, list, this.turnList, this.$route, 'ListReplacementList');
            }
            if (this.$route.params.hash) {
                responseTab = myTab.selectElementWithParamsHash(this.currentTabType, list, this.turnList, this.$route, this.$router, 'ListReplacementList');
            }
            if (!this.$route.params.hash && !this.$route.hash) {
                responseTab = myTab.selectElementWithoutHash(this.currentTabType, list, this.turnList, this.$router, 'ListReplacementList');
            }
            this.currentTabType = responseTab.elementActive;
            this.turnList = responseTab.list;
            this.turnsOnProcess = this.turnList.list.map(turn => ({ id: turn.type, name: InscriptionTurn.translations[turn.type] }));
            this.getInscriptionByTurn(this.turnList.activeTab);
        })
            .catch(error => console.log(error));
    }

    getReplacementListIsModify() {
        ReplacementService.getReplacementListIsModify(this.replacementListId)
            .then(response => {
                this.replacementListModified = response;
            }).catch(error => console.log(error));
    }

    initListReplacement() {
        this.loading = true;
        this.replacementListId = this.$route.params.replacementId;
        this.fromPage = this.$route.params.fromPage;
        this.getReplacementById();
        this.getReplacementListIsModify();
    }

    toggleSlideOver() {
        this.showSlideOver = !this.showSlideOver;
        if (!this.showSlideOver) {
            this.newJobOffer = new JobOffer({});
            this.submitted = false;
        }
    }

    setSendToProperty() {
        if (this.newJobOffer.sendFrom >= this.newJobOffer.sendTo) {
            this.newJobOffer.sendTo = null;
        }
    }

    getReplacementCandidate(replacementListCandidateId: string, event: any) {
        event.stopPropagation();
        ReplacementCandidatesService.getReplacementCandidate(replacementListCandidateId)
            .then(response => {
                this.replacementListCandidateSelected = new ReplacementCandidate(response);
                this.getMunicipalities();
                this.isSlideOverVissible = !this.isSlideOverVissible;
            });
    }

    editReplacementCandidate() {
        if (!this.replacementListCandidateSelected.isValid()) {
            this.submitted = true;
            return;
        }

        ReplacementCandidatesService.editReplacementCandidate(this.replacementListCandidateSelected)
            .then(response => {
                ToasterService.showSuccess(i18n.t('lang.toaster.saveSuccess') as string);
                this.closeReplacementCandidateSlideOver();
                this.initListReplacement();
                this.submitted = false;
            })
            .catch(error => console.log(error));
    }
    
    sendJobOffer() {
        this.submitted = true;

        if (this.$refs.massiveNotificationForm.querySelectorAll(':invalid').length > 0 || this.$refs.massiveNotificationForm.querySelectorAll('.error').length > 0) {
            return;
        }
        this.newJobOffer.replacementListId = this.replacementListId;

        ReplacementService.sendJobOffer(this.newJobOffer.toServer())
            .then(() => {
                ToasterService.showSuccess(i18n.t('lang.toaster.messageSent') as string);
                this.toggleSlideOver();
                this.$refs.sdSlideOver.setOverflowAutoToBody();
                this.submitted = false;
                this.newJobOffer = new JobOffer({});
            })
            .catch(error => console.error(error));
    }

    getCountries() {
        MasterDataService.getCountries().then((response: any) => this.countries = response.map(element => ({ name: element.value, id: element.key }))
        ).catch(error => console.log(error));
    }

    getAutonomousCommunity() {
        MasterDataService.getAutonomousCommunity().then((response: any) => this.autonomousCommunityList = response
        ).catch(error => console.log(error));
    }

    getStreetTypes() {
        MasterDataService.getStreetTypes().then((response: any) => this.streetTypes = response
        ).catch(error => console.log(error));
    }

    getProvinces() {
        MasterDataService.getProvinces().then((response: any) => this.provinces = response
        ).catch(error => console.log(error));
    }

    getNationalities() {
        MasterDataService.getNationalities().then((response: any) => this.nationalities = response.map(element => ({ name: element.value, id: element.key }))
        ).catch(error => console.log(error));
    }

    getMunicipalities() {
        const province = this.replacementListCandidateSelected.address.province ? this.replacementListCandidateSelected.address.province : null;

        if (!province) {
            this.replacementListCandidateSelected.address.municipality = 0;
            return;
        }
               
        this.$nextTick(() => {
            MasterDataService.getMunicipalities(province)
                .then((response: any) => {
                    const candidateMunicipalitie = this.replacementListCandidateSelected ? this.replacementListCandidateSelected.address.municipality : null;
                    this.municipalities = response.map(municipality => ({ name: municipality.municipalityName, id: municipality.municipalityCode, disabled: municipality.isObsolete }))
                        .filter(municipality => !municipality.disabled || (candidateMunicipalitie !== null && candidateMunicipalitie === municipality.id));
                })
                .catch(error => console.log(error));
        });

    }

    getReplacementListCandidateMasterData() {
        this.getCountries();
        this.getAutonomousCommunity();
        this.getStreetTypes();
        this.getProvinces();
        this.getNationalities();
    }

    togglEditReplacementListCandidate() {
        this.isReplacementListCandidateEditting = !this.isReplacementListCandidateEditting;
    }

    closeReplacementCandidateSlideOver() {
        this.isSlideOverVissible = !this.isSlideOverVissible;
        this.$refs.sdSlideOverReplacementCandidateInfo.setOverflowAutoToBody();
        this.isReplacementListCandidateEditting = false;
    }

    toggleMoveReplacementCandidateModal(payload?: {replacementListCandidateId: string, position: number}) {
        if (payload) {
            this.showMoveReplacementCandidateModal = true;
            this.candidatePosition = payload.position;
            this.candidateToMove = payload.replacementListCandidateId;
            return;
        }
        this.showMoveReplacementCandidateModal = false;
        this.candidateToMove = '';
    }

    moveCandidate(movePayload: {moveOption: number, reason?: string, order?: number} ) {
        const moveCandidateCommand = {
            ... movePayload,
            replacementListId: this.replacementListId,
            replacementListCandidateId: this.candidateToMove
        };
        
        ReplacementService.updateReplacementListCandidatePosition(moveCandidateCommand)
            .then(response => {
                const messageToShow = moveCandidateCommand.moveOption === CandidateMoveOption.MOVE_TO_END 
                ? i18n.t('lang.replacement.candidateSendedToEnd')
                : i18n.t('lang.replacement.candidateSendedToPosition', {order: moveCandidateCommand.order});
                ToasterService.showSuccess(messageToShow as any);
                this.toggleMoveReplacementCandidateModal();
                this.initListReplacement();
            }).catch(error => console.log(error));  
    }

    mounted() {
        this.initListReplacement();
        this.inscriptionsExcludedOrResignation = this.seeInscriptionsExcludedOrResignation;
        this.getReplacementListCandidateMasterData();
        this.$nextTick(() => {
            sdPanel.initialize();
        });
    }
}
