import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/inscriptions';
import csvService from '@/Services/CsvService';

export default class InscriptionService {

    public static async getInscriptionByNIF(processId: string, nif: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.post('', { nif, processId }, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscription(data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(data.inscriptionId + '/finish', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionPersonalDataDetails(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/personalDataDetails', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionAddress(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/address', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionContactData(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/contactData', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionInscriptionDate(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/inscriptionDate', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionAuthorization(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/authorization', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionPayExemption(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/payExemption', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionDisabilityTurn(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/disabilityTurn', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionLanguageSelected(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/examLanguage', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionMerit(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/merits/' + data.merit.meritId, data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async setStatusSelectedDocMerit(inscriptionId: string, file: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.patch(inscriptionId + '/merits/' + file.meritId + '/documents/' + file.id + '/status', {status: file.status}, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addFileToInscription(data: any): Promise<any[]> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.post(data.inscriptionId + '/files', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async copyFileToInscription(inscriptionId: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/merits/copy', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getCSVTemplate(): Promise<any[]> {
        return csvService.getCsv(baseURL, '/candidateLoadTemplate');
    }

    public static async importCSVLoadCandidates(data: any): Promise<any[]> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.post(data.processId + '/loadCsv', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async addJustifyFileToInscription(data: any): Promise<any[]> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.post(data.inscriptionId + '/justifyFiles', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async editJustifyFileToInscription(data: any, fileId): Promise<any[]> {
        const formData = new FormData();
        Object.entries(data).forEach(key => formData.append(key[0], (key[1] as any)));
        return new Promise((resolve, reject) => {
            axios.post(data.inscriptionId + '/justifyFiles/' + fileId, formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))                 
                .catch(error => reject());
        });
    }

    public static async removeFileFromInscription(id: string, fileId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(id + '/files/' + fileId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeJustifyFileFromInscription(id: string, fileId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(id + '/justifyFiles/' + fileId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeInscription(id: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.delete(id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getInscriptionsManagementData(id: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(id + '/managementData', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getInscriptionsMeritsData(id: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(id + '/merits', { baseURL })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject());
        });
    }

    public static async putInscriptionsManagementData(id: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(id + '/managementData', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionsManagementMertis(id: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(id + '/merits', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async putInscriptionsManagementContact(id: string, data: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.put(id + '/rankedStatus', data, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getHistoryProcessExamsGrades(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(id + '/gradeScaleSummary', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    
    public static async updateReadmitanceReason(inscriptionId: string, readmitanceReason: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(inscriptionId + '/readmitance', { readmitanceReason }, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
